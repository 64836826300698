.loader {
  display: inline-block;
  position: relative; }
  .loader.center {
    width: 100%;
    height: 100%; }
    .loader.center > .inner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

@keyframes move {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
  .loader > .inner div:first-of-type {
    background: url(../../assets/images/ic_progress_shadow.png) no-repeat;
    background-size: cover; }
  .loader > .inner div:last-of-type {
    position: absolute;
    background: url(../../assets/images/ic_progress.png) no-repeat;
    background-size: cover;
    animation: move linear 2s infinite; }
