.NotificationMessages {
  position: fixed;
  top: 135px;
  width: 100%;
  height: 0 !important;
  z-index: 2000;
  text-align: center; }
  .NotificationMessages > .message {
    display: inline-block;
    min-width: 400px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: opacity 1s; }
    @media (max-width: 575.98px) {
      .NotificationMessages > .message {
        min-width: unset;
        width: 100%; } }
    .NotificationMessages > .message.success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6; }
    .NotificationMessages > .message.info {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1; }
    .NotificationMessages > .message.warning {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc; }
    .NotificationMessages > .message.error {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1; }
    .NotificationMessages > .message.close {
      opacity: 0; }
